import { makeAxiosRequest } from 'utils/apiMethods';

export default {
  getStates: makeAxiosRequest('/states', { method: 'get' }, true),
  getBasins: makeAxiosRequest('/states/{`id`}/basins', { method: 'get' }, true),
  getCounties: makeAxiosRequest(
    '/states/{`id`}/basins/{`basinId`}',
    { method: 'get' },
    true,
  ),
  getLandingZones: makeAxiosRequest(
    '/states/{`id`}/basins/{`basinId`}/counties/{`countyId`}',
    { method: 'get' },
    true,
  ),
  sendPropertyData: makeAxiosRequest('/properties', { method: 'post' }),
  sendPropertyDocuments: makeAxiosRequest('/properties/{`id`}/documents', {
    method: 'post',
  }),
  sendDraftListingData: makeAxiosRequest('/properties/drafts', {
    method: 'post',
  }),
  getProperties: makeAxiosRequest('/properties', { method: 'get' }),
  getListingsForSeller: makeAxiosRequest('/sellers/{`sellerId`}/properties', {
    method: 'get',
  }),
  getStatuses: makeAxiosRequest(
    '/properties/statuses',
    { method: 'get' },
    true,
  ),
  getPreSignedUrls: makeAxiosRequest(
    '/get-s3-pre-signed-urls-for-listing-uploads',
    { method: 'get' },
  ),
  getCurrentProperty: makeAxiosRequest('/properties/{`id`}', { method: 'get' }),
  makeBet: makeAxiosRequest('/properties/{id}/bids', { method: 'post' }),
  editProperty: listingId => makeAxiosRequest(`/properties/${listingId}`, { method: 'put' }, true),
  editDraftProperty: listingId => makeAxiosRequest(`/properties/draft/${listingId}`, { method: 'put' }, true),
  addMorePropertyDocuments: listingId => makeAxiosRequest(
    `/listings/${listingId}/documents`,
    { method: 'post' },
    true,
  ),
  addListingPageView: listingId => makeAxiosRequest(
    `/listings/${listingId}/pageViews`,
    { method: 'post' },
    true,
  ),
  addListingDocumentDownload: (listingId, documentId) => makeAxiosRequest(
    `/listings/${listingId}/documents/${documentId}/downloads`,
    { method: 'post' },
    true,
  ),
  getListingAnalytics: listingId => makeAxiosRequest(
    `/listings/${listingId}/analytics/main`,
    { method: 'get' },
    true,
  ),
  getClosingMethods: makeAxiosRequest('/closing-methods', { method: 'get' }),
};
